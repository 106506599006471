function b64DecodeUnicode(str) {
  return decodeURIComponent(
    atob(str).replace(/(.)/g, (_, p) => {
      let code = p.charCodeAt(0).toString(16).toUpperCase();
      if (code.length < 2)
        code = '0' + code;
      return '%' + code;
    }),
  );
}


function base64UrlDecode(str) {
  let output = str.replace(/-/g, '+').replace(/_/g, '/');
  switch (output.length % 4) {
    case 0:
      break;
    case 2:
      output += '==';
      break;
    case 3:
      output += '=';
      break;
    default:
      throw new Error('Invalid length of base64 string');
  }

  try {
    return b64DecodeUnicode(output);
  } catch (e) {
    return atob(output);
  }
}


async function opFetch(url, authToken, data) {
  const options = {
    method: 'POST',
    mode: 'cors',
    cache: 'no-store',
    headers: {}
  };

  if (data) {
    options.body = new FormData();
    const blobs = [];
    function replaceBlobs(key, value) {
      if (value instanceof Blob) {
        const i = blobs.push(value) - 1;
        return { '__file__': String(i) };
      }
      return value;
    }
    const serialized = JSON.stringify(data, replaceBlobs);
    if (blobs.length > 0) {
      options.body.append('_', serialized);
      for (let i = 0; i < blobs.length; i++) {
        options.body.append(i, blobs[i]);
      }
    } else {
      options.headers['content-type'] = 'application/json';
      options.body = serialized;
    }
  }

  if (authToken)
    options.headers['authorization'] = `Bearer ${authToken}`;

  const response = await fetch(encodeURI(url), options);
  if (response.ok) {
    if (response.headers.get('content-type') == 'application/json')
      return await response.json();
    return await response.blob();
  }

  const e = new OPServiceClientError(response);
  try {
    const content = await response.json();
    if (content.hasOwnProperty('err') && content.hasOwnProperty('msg')) {
      e.name = content.err;
      e.message = content.msg;
    }
  } catch (e) {
    // We couldn't deserialize the response as JSON
  }
  throw e;
}


class OPServiceClientError extends Error {

  constructor(response) {
    super(response.status);
    this.response = response;
  }

}


class OPServiceClient {

  constructor(baseURL) {
    this.base = baseURL + (baseURL.endsWith('/') ? '' : '/');
    this.localStorageKey = process.env.OP_DEV_METADATA.pkg_id + '.' + this.base;
  }

  _buildURL(path) {
    return this.base + (path.startsWith('/') ? path.substr(1) : path);
  }

  async call(path, data) {
    let token = this.profile && localStorage.getItem(this.localStorageKey);
    if (token && this.profile.exp < (new Date()).getTime() / 1000 + 10)
      // Our current token has expired (or will within the next 10 ms). Try to refresh it. Note that we continue with
      // the call even if we are unable to refresh it, as the call may not actually require authentication.
      token = await this._refresh(token);
    try {
      return await opFetch(this._buildURL(path), token, data)
    } catch (e) {
      // TODO: you may want to handle ExpiredToken errors, though that shouldn't happen since we're optimistically
      // refreshing the token before the call
      throw e;
    }
  }

  async _refresh(token) {
    if (!this._refreshPromise)
      this._refreshPromise = new Promise(
        async r => {
          try {
            r(await this.auth('refresh', token));
          } catch (e) {
            r(null);
          } finally {
            this._refreshPromise = null;
          }
        }
      );
    return await this._refreshPromise;
  }

  async auth(method, data) {
    // Use this if you want to authenticate yourself and have more control over exactly what you pass to the service
    const jwt = await opFetch(this._buildURL('__private/auth'), null, { method, data });
    localStorage.setItem(this.localStorageKey, jwt);
    return jwt;
  }

  deauth() {
    localStorage.removeItem(this.localStorageKey);
  }

  show(methods) {
    throw new Error('Not implemented');
  }

  get profile() {
    // Returns the valid decoded profile if there is one, otherwise undefined
    try {
      const jwt = localStorage.getItem(this.localStorageKey);
      return JSON.parse(base64UrlDecode(jwt.split('.')[1]));
    } catch (e) {
    }
  }

}


// Ensure that our instances of OPServiceClient are singletons
const CLIENTS = {};


export default url => CLIENTS[url] ||= new OPServiceClient(url);
